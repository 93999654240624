.custom-btn {
  width: 30px;
  height: 30px;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #040b29;
  color: white;
  border: 0px solid;
}

.jumlah_produk {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-left: 10px;
}

.button-delete button {
  width: 100%;
  padding: 10px 0px 10px 0px;
  background-color: #040b29;
  color: white;
  border: 0px solid;
  border-radius: 5px;
}

.button-checkout button {
  border: 0px;
  color: #040b29;
  padding: 10px 2rem 10px 2rem;
  width: 80%;
  border-radius: 5px;
  transition: all 0.3s;
}

.button-checkout button:hover {
  background-color: lightslategray;
  color: white;
}

.keranjang-belanja {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tabel-keranjang {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.tabel-keranjang th,
.tabel-keranjang td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

.tabel-keranjang th {
  background-color: #f0f2f5;
}

.jumlah-barang {
  display: flex;
  align-items: center;
}

.jumlah-barang button {
  background-color: #f0f2f5;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
}

.jumlah-barang input[type="number"] {
  width: 50px;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0 8px;
  padding: 8px;
}

.total-keranjang {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.subtotal,
.kode-promo,
.total {
  margin-bottom: 10px;
}

.subtotal,
.total {
  font-weight: bold;
}

.kode-promo input[type="text"] {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-right: 10px;
}

.kode-promo button {
  background-color: #1890ff;
  border: none;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.tombol-checkout {
  background-color: #1890ff;
  border: none;
  color: #fff;
  padding: 12px 24px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tombol-checkout:hover {
  background-color: #40a9ff;
}

.total-harga,
.alamat {
  margin-bottom: 20px;
}

.total-harga label,
.alamat label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.total-harga input,
.alamat input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  color: black;
  border-radius: 5px;
  font-size: 16px;
}

.button-checkout {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-checkout:hover {
  background-color: black;
  color: white;
}

.metode-pembayaran {
  margin-bottom: 20px;
}

.metode-pembayaran label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.metode-pembayaran select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.metode-pembayaran select option {
  font-size: 16px;
}
